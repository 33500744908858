import React from 'react';
import moment from 'moment';

function HubListComp(props) {

    return (
      <>
        <a href="#" onClick={(e) => { props.openHubModal(e, props.data.id)}}>
            <div className="d-flex mb-3">
                <div className="img-logo">
                    <img src={props.data.logo} alt={props.data.title} />
                </div>
                <p className="heading ms-2">{props.data.title}</p>
            </div>
            <p className="sub-content">
                {props.data.key_benefit
                    ? <>Key Benefit: <span>{props.data.key_benefit}</span></>
                    : <>&nbsp;</>
                }</p>
            <p className="sub-content">
                Age:&nbsp;
                {props.data.age_details.map((age_d, age_index) => (
                    <span key={`hub_age${age_index}`}>
                        {age_d.label}
                        {age_index !==props.data.age_details.length - 1 && (<>, &nbsp;</>)}
                    </span>
                ))}
            </p>
            <p className="sub-content">Expiry: {moment(props.data.expiry_at).format("Do MMM YYYY")}</p>
            <p className="text-end me-3 mb-0">
                {props.data.fee_id !== 13
                    ? <span>{props.data.fee}</span>
                    : <>&nbsp;</>
                }
            </p>
        </a>
      </>
    );
  }
  
  export default HubListComp;