import React, {useState, useEffect, useRef} from "react";
import { useLocation } from "react-router-dom";
import {SetMetaData} from './../../helper/page-title';
import Select from 'react-select';
import './../../assets/css/pages/team.css';
import './../../assets/css/pages/portfolio.css';
import {get_api_call, post_api_call, error_api_call} from '../../actions/action-creators/common';
import {alertResponseMessage} from '../../actions/action-creators/response';
import { RSstandardStyle } from "../../config/react-select";
import {toggleBodyLoader} from '../../helper/custom-function';
import {LoaderMedium} from './../../components/loader';
import noResultSection from '../../assets/images/oops.png';
import HubListComp from "../../components/hub/list";
import ModalHubInfo from "../../components/modal/portfolio/hub-info";
const selectOptionLabel = {age_id: 'label',
                        course_id: 'label',
                        type_id: 'label',
                        mode_id: 'label',
                        is_reserved: 'label',
                        expiry_id: 'label'};
const oppModesOpt = [
    {id:  '', label: 'All'},
    {id:  1, label: 'Online'},
    {id:  2, label: 'Physical'},
    {id:  3, label: 'Hybrid'}
];
// const oppIsReserved =  [
//     {id:  '', label: 'All'},
//     {id:  1, label: 'Yes'},
//     {id:  2, label: 'No'}
// ];
const oppExpiryOpt = [
    {id:  '', label: 'All'},
    {id:  1, label: 'Soon'},
    {id:  2, label: 'Less than 1 month'},
    {id:  3, label: 'More than 1 month'}
];

function HubList(){
    SetMetaData("Opportunities", "", "");
    const location = useLocation();
    const [filterData, setFilterData] = useState({age_id: {id:  4, label: 'All'},
                                                course_id: {id:  5, label: 'All'},
                                                type_id: {id:  '', label: 'All'},
                                                mode_id: {id:  '', label: 'All'},
                                                is_reserved: {id:  '', label: 'All'},
                                                expiry_id: {id:  '', label: 'All'}});
    const [masterData, setMasterData] = useState([]);
    const [dataListPage, setListPage] = useState(0);
    const [callFellowAPI, setCallFellowAPI] = useState(0);
	//Loading
	const [showListLoader, setListLoader] = useState(true);
	const [listLoading, setListLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(true);
    const [hubInfoLoading, setHubInfoLoader] = useState(true);
    const [hubModalDetails, setHubModalData] = useState({});
    const [hubInfoDetails, setHubInfoData] = useState({});
	const limit = 15;
	const loadMoreOppo= useRef(null);//Button Click
	const listLoader = useRef(null);
    //Past
    const [dataListPagePast, setListPagePast] = useState(0);
    const [listLoadingPast, setListLoadingPast] = useState(false);
    const [showListLoaderPast, setListLoaderPast] = useState(true);
    const [pageLoadingPast, setLoaderPast] = useState(true);
    const [masterDataPast, setMasterDataPast] = useState([]);
    const loadMorePast= useRef(null);//Button Click
    const listLoaderPast = useRef(null);
    const [callFellowAPIPast, setCallFellowAPIPast] = useState(1);
    //Options Data
    const [oppTypeOpt, setOppTypeOpt] = useState([]);
    const [oppAgeOpt, setOppAgeOpt] = useState([]);
    const [oppCourseOpt, setOppCourseOpt] = useState([]);
    const [oppIsReserved, setOppIsReserved] = useState([]);
    const [modalToggle, setModalToggle] = useState({hub_info: false});
    // Update Filter Input
    const updateInput = (input_type, field, value, select_option='') => {
        try{
            if(field === 'name'){
                value = value.replace(/[^a-zA-Z. ]/g, '')
                            .replace('  ', ' ');
            }
            const filledFilterData = {...filterData, [field]: value};
            // setFilterData({...filterData, [field]: value});
            setFilterData({...filledFilterData});
            if(field !== 'name'){
                console.log('yes')
                // searchFellow();
                // setListPage(1);
                toggleBodyLoader('load');
                if(filledFilterData[field].id === ''){
                    modifyAdBarUrl(field, '');
                }else{
                    modifyAdBarUrl(field, `${filledFilterData[field].id},${filledFilterData[field][select_option]}`);
                }
                setPageLoading(true);
                setListPage(dataListPage === 1 ? 0 : 1);
            }
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error Loading Details! Check back after some times"});
            // console.log(e)
            error_api_call({error: `hub/list|updateInput|${e}`});
            return false;
        }
    }
    //Search by Filter Data
    // const searchFellow = (e) => {
    //     try{
    //         e.preventDefault();
    //         toggleBodyLoader('load');
    //         modifyAdBarUrl('name', filterData.name);
    //         setPageLoading(true);
    //         setListPage(dataListPage === 1 ? 0 : 1);
    //     }catch(e){
    //         alertResponseMessage({alert_type: 2, message: "Error Loading Details! Check back after some times"});
    //         // console.log(e)
    //         error_api_call({error: `hub/list|searchFellow|${e}`});
    //     }
    // }
    //Get Data from APIs
    const fetchSelectList = async(type, url) => {
        try{
            const selectApiData = await get_api_call(url, {});
            var data = [];
            if(selectApiData.status === 200){
                data = selectApiData.data;
                switch (type) {
                    case 'opp_type':
                      // setOppTypeOpt([...selectApiData.data, {id: 0, label: 'Others'}]);
                      setOppTypeOpt([...selectApiData.data]);
                      break;
                    case 'opp_age':
                      setOppAgeOpt(selectApiData.data);
                      break;
                    case 'opp_course':
                      setOppCourseOpt(data);
                      break;
                    case 'is_reserved':
                        setOppIsReserved(data);
                        break;
                    default: 
                }
            }
            return {
                data
            };
        }catch(e){
            error_api_call({error: `screen|hub|list|fetchSelectList|${e}`});
            return {data: []};
        }
      }
    //Modify URL
	const modifyAdBarUrl = (key, value) => {
		try{
			// if(typeof value === 'array'){
			// if(value instanceof Array){
				const url = new URL(window.location.href);
				if(!value){//value.length === 0
					url.searchParams.delete(key);
				}else{
					url.searchParams.set(key, value);
				}
				
				window.history.pushState({}, '', url.toString());
			// }
		}catch(e){
			// console.log(e)
			error_api_call({error: `hub/list|modifyAdBarUrl|${e}`});
			return false;
        }
	};
	//Load More Data when loader is in focus
	const infiniteCustomScroll = () => {
		if(loadMoreOppo.current){
			loadMoreOppo.current.click();
		}
	}
	//Initial Load & Read More Implementation
	useEffect(() => {
        const options = {
           root: null,
           rootMargin: "20px",
           threshold: 1.0
        };
       // initialize IntersectionObserver
       // and attaching to Load More div
        //For All
		const observerAll = new IntersectionObserver(infiniteCustomScroll, options);
        if (listLoader.current) {
            observerAll.observe(listLoader.current)
        }
		const clean = listLoader.current;
        return () => {
            if (clean) {
                observerAll.disconnect(clean);
            }
        }
    }, [listLoader.current]);// eslint-disable-line react-hooks/exhaustive-deps
    //Call Filter Data
	useEffect(() => {
		const urlHandling = () => {
			try{
				//URL Param type Handling
				const urlQuryString = location.search;
				const urlParam = new URLSearchParams(urlQuryString);
				if(urlParam.size > 0){
                    const filterKeys = ['age_id', 'course_id', 'type_id', 'mode_id', 'is_reserved', 'expiry_id'];
					var filterDataNew = {...filterData};
					urlParam.forEach((value, key) => {
						if(filterKeys.indexOf(key) > -1){
                            if(key !== 'name'){
                                var urlParamData = value.split(',');
                                filterDataNew[key] = {id: parseInt(urlParamData[0]), [selectOptionLabel[key]]: urlParamData[1]};
                            }else{
                                filterDataNew[key] = value;
                            }
							console.log(value, key);
						}
					});
                    console.log(filterDataNew, 'filterDataNewfilterDataNewfilterDataNew')
					// setFilterData({...filterDataNew});
                    // setTimeout(() => {
                        setFilterData({...filterDataNew});
                    // }, 13000)
                    
                    console.log(filterData, 'filterDatafilterDatafilterData')
				}
				setCallFellowAPI(1);
				setListPage(dataListPage === 1 ? 0 : 1);
			}catch(e){
				alertResponseMessage({alert_type: 2, message: "Error Loading Fellows! Check back after some times"});
				error_api_call({error: `hub/list|urlHandling|${e}`});
			}
		}

        fetchSelectList('opp_type', 'opportunity/options/1');
        fetchSelectList('opp_age', 'opportunity/options/3?order_by=id');
        fetchSelectList('opp_course', 'opportunity/options/4');
        fetchSelectList('is_reserved', 'opportunity/options/7');
        urlHandling();
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
    //Call Opp Filter API
	useEffect(() => {
		//Get Filtered Opp
		const fetchData = async() => {
			try{
				//APi Call
				if(!listLoading){
					setListLoading(true);
					const listCurrentPage = dataListPage < 2 ? 1 : dataListPage;
					if(listCurrentPage === 1){
						setMasterData([]);
					}
					const newMasterList = await post_api_call('opportunities', 
																{limit,
																page: listCurrentPage,
																filter: {type_id: filterData.type_id !== '' ? filterData.type_id.id : '',
                                                                        age_id: filterData.age_id !== '' && filterData.age_id.id !== 4 ? filterData.age_id.id : '',
                                                                        course_id: filterData.course_id !== '' && filterData.course_id.id !== 5 ? filterData.course_id.id : '',
                                                                        mode_id: filterData.mode_id !== '' ? filterData.mode_id.id : '',
                                                                        is_reserved: filterData.is_reserved !== '' ? filterData.is_reserved.id : '',
                                                                        expiry_id: filterData.expiry_id !== '' ? filterData.expiry_id.id : ''
                                                                    },
																// keyword: queryKeyword,
															});
					if(newMasterList.status === 200){
						const newFellowData = listCurrentPage < 2
												? newMasterList.data 
												: masterData.concat(newMasterList.data);
						setMasterData(newFellowData);
						if(listCurrentPage === 1){
							// setFellowTotal(newMasterList.total);
							setPageLoading(false);
						}
					}
					setListLoading(false);
					toggleLoader(listCurrentPage, newMasterList.total);
					if(listCurrentPage === 1){
						toggleBodyLoader('remove');
					}
				}
			}catch(e){
				alertResponseMessage({alert_type: 2, message: "Error Loading Details! Check back after some times"});
				// console.log(e);
				setListLoading(false);
				error_api_call({error: `hub/list|fetchData|${e}`});
			}
		}
		if(callFellowAPI === 1){
			fetchData();
		}
	}, [dataListPage]);// eslint-disable-line react-hooks/exhaustive-deps
	//Load More Fellow
	const loadMore = () => {
        if(!listLoading){
		    setListPage(dataListPage < 2 ? 2 : dataListPage+1);
        }
	}
	//Show-Hide Item Loader
	const toggleLoader = (current_page, total_page) => {
        try{
            var response = {page_count: 0, is_show: false};
			const totalPage = total_page <= limit
							? 1
							: total_page % limit === 0 
							? total_page/limit 
							: parseInt((total_page/limit))+1;
							// console.log(current_page, 'current_page')
							// console.log(totalPage, 'totalPage')
			if(current_page < totalPage){
				response.is_show = true;
				setListLoader(true);
			}else{
				setListLoader(false);
			}
			response.page_count = totalPage;
            return response;
        }catch(e){
            return response;
        }
    }
    // Toggle Modal
    const toggleModal = (e, modal_type, toggleState, index='', form_type='') => {
        try{
            if(toggleState){
                e.preventDefault();
            }
            setModalToggle({...modalToggle, [modal_type]: toggleState});
        }catch(error){
            console.log(error)
        }
    }
    //Show Opportunity Details
    const openHubModal = async(e, oppertunity_id) => {
        try{
            e.preventDefault();
            // Show Loader
            setHubInfoLoader(true);
            //Open Modal
            toggleModal(e, 'hub_info', true);
            //Fetch Data
            if(typeof hubInfoDetails[oppertunity_id] !== "undefined"){
                setHubModalData({...hubInfoDetails[oppertunity_id]});
            }else{
                const hubApiData = await post_api_call(`opportunity/details/${oppertunity_id}`, {});
                if(hubApiData.status === 200){
                    setHubModalData({...hubApiData.data});
                    setHubInfoData({...hubInfoDetails, [oppertunity_id]: hubApiData.data});
                }else{
                    alertResponseMessage({alert_type: 2, message: "Error loading data."});
                }
            }
            setHubInfoLoader(false);
        }catch(error){
            console.log(error)
            alertResponseMessage({alert_type: 2, message: "Error loading data."});
            error_api_call({error: `hub/edit|openHubModal|${error}`});
        }
    }
    //Past
    //Call Opp Filter API
    useEffect(() => {
        //Get Filtered Opp
        const fetchDataPast = async() => {
            try{
                //APi Call
                if(!listLoadingPast){
                    setListLoadingPast(true);
                    const listCurrentPage = dataListPagePast < 2 ? 1 : dataListPagePast;
                    if(listCurrentPage === 1){
                        setMasterDataPast([]);
                    }
                    const newMasterList = await post_api_call('opportunities', 
                                                                {limit,
                                                                page: listCurrentPage,
                                                                filter: {},
                                                                is_past: true
                                                                // keyword: queryKeyword,
                                                            });
                    if(newMasterList.status === 200){
                        const newFellowData = listCurrentPage < 2
                                                ? newMasterList.data 
                                                : masterDataPast.concat(newMasterList.data);
                        setMasterDataPast(newFellowData);
                        if(listCurrentPage === 1){
                            // setFellowTotal(newMasterList.total);
                            setLoaderPast(false);
                        }
                    }
                    setListLoadingPast(false);
                    toggleLoaderPast(listCurrentPage, newMasterList.total);
                    // if(listCurrentPage === 1){
                    //     toggleBodyLoader('remove');
                    // }
                }
            }catch(e){
                alertResponseMessage({alert_type: 2, message: "Error Loading Details! Check back after some times"});
                // console.log(e);
                setListLoadingPast(false);
                error_api_call({error: `hub/list|fetchDataPast|${e}`});
            }
        }
        if(callFellowAPIPast === 1){
            fetchDataPast();
        }
    }, [dataListPagePast]);// eslint-disable-line react-hooks/exhaustive-deps
    //Show-Hide Item Loader
	const toggleLoaderPast = (current_page, total_page) => {
        try{
            var response = {page_count: 0, is_show: false};
			const totalPage = total_page <= limit
							? 1
							: total_page % limit === 0 
							? total_page/limit 
							: parseInt((total_page/limit))+1;
							// console.log(current_page, 'current_page')
							// console.log(totalPage, 'totalPage')
			if(current_page < totalPage){
				response.is_show = true;
				setListLoaderPast(true);
			}else{
				setListLoaderPast(false);
			}
			response.page_count = totalPage;
            return response;
        }catch(e){
            return response;
        }
    }
    //Load More Data when loader is in focus
	const infiniteCustomScrollPast = () => {
		if(loadMorePast.current){
			loadMorePast.current.click();
		}
	}
	//Initial Load & Read More Implementation
	useEffect(() => {
        const options = {
           root: null,
           rootMargin: "20px",
           threshold: 1.0
        };
       // initialize IntersectionObserver
       // and attaching to Load More div
        //For All
		const observerAll = new IntersectionObserver(infiniteCustomScrollPast, options);
        if (listLoaderPast.current) {
            observerAll.observe(listLoaderPast.current)
        }
		const clean = listLoaderPast.current;
        return () => {
            if (clean) {
                observerAll.disconnect(clean);
            }
        }
    }, [listLoaderPast.current]);// eslint-disable-line react-hooks/exhaustive-deps
	//Load More Past Oppo
	const loadMorePastOpp = () => {
        if(!listLoadingPast){
		    setListPagePast(dataListPagePast < 2 ? 2 : dataListPagePast+1);
        }
	}
    
    return (
        <>
        <main id="main">
        <section className="team-sec pt-0">
          <div className="container-fluid container-lg pe-lg-5 ps-lg-5">
              <div className="section-header section-header-1">
                  <h1 className="title">Opportunities</h1>
              </div>
              <div className="mb-5 p-3" style={{backgroundColor: '#f8f9fa', borderRadius: 5,position: 'relative', zIndex: 99}}>
                <div className="row justify-content-center">
                    {/* <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="input-group">
                            <input className="form-control" placeholder="Search by Name" value={filterData.name} onChange={(e) => updateInput('input', 'name', e.target.value)}/>
                            <div className="input-group-append">
                                <button className="input-group-text" style={{height: 38}} onClick={(e) => searchFellow(e)}><i className="fa fa-search"></i></button>
                            </div>
                        </div>
                    </div> */}
                    {/* Age */}
                    <div className="col-lg-2 col-md-2 col-sm-12 ps-1 pe-1">
                        <span className="fs-12p text-black-50">Age</span>
                        <Select
                            // options={[{id: '', label: 'All'}].concat(oppAgeOpt)}
                            options={oppAgeOpt} 
                            // defaultValue={filterData.age_id}
                            value={filterData.age_id}
                            placeholder={"Age"}
                            onChange={(value) => updateInput('select-react', 'age_id', value, 'label')}
                            getOptionLabel ={(option) => option.label}
                            getOptionValue ={(option) => option.id}
                            menuPlacement="auto"
                            menuPosition={'fixed'}
                            styles={RSstandardStyle}
                        />
                    </div>
                    {/* Course */}
                    <div className="col-lg-2 col-md-2 col-sm-12 ps-1 pe-1">
                        {/* <div className="w-110p"> */}
                            <span className="fs-12p text-black-50">Course</span>
                            <Select
                                options={oppCourseOpt}
                                value={filterData.course_id}
                                defaultValue={filterData.course_id}
                                placeholder={"Course"}
                                onChange={(value) => updateInput('select-react', 'course_id', value, 'label')}
                                getOptionLabel ={(option) => option.label}
                                getOptionValue ={(option) => option.id}
                                menuPlacement="auto"
                                menuPosition={'fixed'}
                                styles={RSstandardStyle}
                            />
                        {/* </div> */}
                    </div>
                    {/* Type */}
                    <div className="col-lg-2 col-md-2 col-sm-12 ps-1 pe-1">
                        {/* <div className="w-200p"> */}
                        <span className="fs-12p text-black-50">Type</span>
                            <Select
                                options={[{id: '', label: 'All'}].concat(oppTypeOpt)}
                                value={filterData.type_id}
                                defaultValue={filterData.type_id}
                                placeholder={"Type"}
                                onChange={(value) => updateInput('select-react', 'type_id', value, 'label')}
                                getOptionLabel ={(option) => option.label}
                                getOptionValue ={(option) => option.id}
                                styles={RSstandardStyle}
                                menuPlacement="auto"
                                menuPosition={'fixed'}
                            />
                        {/* </div> */}
                    </div>
                    {/* Mode */}
                    <div className="col-lg-2 col-md-2 col-sm-12 ps-1 pe-1">
                        {/* <div className="w-200p"> */}
                            <span className="fs-12p text-black-50">Mode</span>
                            <Select
                                options={oppModesOpt}
                                value={filterData.mode_id}
                                defaultValue={filterData.mode_id}
                                placeholder={"Mode"}
                                onChange={(value) => updateInput('select-react', 'mode_id', value, 'label')}
                                getOptionLabel ={(option) => option.label}
                                getOptionValue ={(option) => option.id}
                                styles={RSstandardStyle}
                                menuPlacement="auto"
                                menuPosition={'fixed'}
                            />
                        {/* </div> */}
                    </div>
                    {/* Reserved */}
                    <div className="col-lg-2 col-md-2 col-sm-12 ps-1 pe-1">
                        {/* <div className="w-200p"> */}
                        <span className="fs-12p text-black-50">Reservation</span>
                            <Select
                                options={oppIsReserved}
                                value={filterData.is_reserved}
                                defaultValue={filterData.is_reserved}
                                placeholder={"Is Reserved?"}
                                onChange={(value) => updateInput('select-react', 'is_reserved', value, 'label')}
                                getOptionLabel ={(option) => option.label}
                                getOptionValue ={(option) => option.id}
                                styles={RSstandardStyle}
                                menuPlacement="auto"
                                menuPosition={'fixed'}
                            />
                        {/* </div> */}
                    </div>
                    {/* Expiry */}
                    <div className="col-lg-2 col-md-2 col-sm-12 ps-1 pe-1">
                        {/* <div className="w-200p"> */}
                            <span className="fs-12p text-black-50">Expiry Date</span>
                            <Select
                                options={oppExpiryOpt}
                                value={filterData.expiry_id}
                                defaultValue={filterData.expiry_id}
                                placeholder={"Expiry"}
                                onChange={(value) => updateInput('select-react', 'expiry_id', value, 'label')}
                                getOptionLabel ={(option) => option.label}
                                getOptionValue ={(option) => option.id}
                                styles={RSstandardStyle}
                                menuPlacement="auto"
                                menuPosition={'fixed'}
                            />
                        {/* </div> */}
                    </div>
                    {/* <div className="col-lg-3 col-md-3 col-sm-12">
                    <button className="search-btn" onClick={(event) => searchFellow(event)}>Search</button>
                    </div> */}
                </div>
              </div>
              <div className="row justify-content-center">
                <div className={`col-md-12 col-12 col-sm-12`}>
					{pageLoading 
					?
						<div className="text-center mt-5">
							<LoaderMedium size={65} />
						</div>
					:
						masterData.length > 0 ?
							<>
                            <div className="row hub-list">
								{masterData.map((m_data, m_indx) => (
                                    <div className="col-md-4 col-12 col-sm-6 p-0 mb-3" key={`hub_${m_indx}`}>
                                        <div className="hub-lst-content">
                                        <HubListComp
                                            data={m_data}
                                            openHubModal={(e, hub_id) => openHubModal(e, hub_id)}
                                        />
                                        </div>
                                    </div>
                                ))}
                            </div>
								{showListLoader && 
									<div className="text-center">
										<div ref={listLoader}>
											<LoaderMedium />
										</div>
										<button className="btn btn-primary" ref={loadMoreOppo} onClick={() => {loadMore()}}>Load More</button>
									</div>
								}
							</>
						: 
						<div className="row">
							<div className="no-fellow mt-4">
								<img src={noResultSection} alt="No Matching Opportunity Found" />
								<p className="pt-5 head">Sorry, no matching Opportunity found!</p>
								<p className="sub-title ml-2 mr-2">Please try with different filter</p>
							</div>
						</div>
					}
				</div>
              </div>
          </div>
          {/* Past Oppo */}
          {masterDataPast.length > 0 && (
          <div className="container-fluid container-lg pe-lg-5 ps-lg-5 mt-5">
              <div className="section-header section-header-1">
                  <h1 className="title">Past Opportunities</h1>
              </div>
              <div className="row justify-content-center">
                <div className={`col-md-12 col-12 col-sm-12`}>
					{pageLoadingPast
					?
						<div className="text-center mt-5">
							<LoaderMedium size={65} />
						</div>
					:
                        <>
                        <div className="row hub-list">
                            {masterDataPast.map((m_data, m_indx) => (
                                <div className="col-md-4 col-12 col-sm-6 p-0 mb-3" key={`past_hub_${m_indx}`}>
                                    <div className="hub-lst-content">
                                    <HubListComp
                                        data={m_data}
                                        openHubModal={(e, hub_id) => openHubModal(e, hub_id)}
                                    />
                                    </div>
                                </div>
                            ))}
                        </div>
                            {showListLoaderPast && 
                                <div className="text-center">
                                    <div ref={listLoaderPast}>
                                        <LoaderMedium />
                                    </div>
                                    <button className="btn btn-primary" ref={loadMorePast} onClick={() => {loadMorePastOpp()}}>Load More</button>
                                </div>
                            }
                        </>
					}
				</div>
              </div>
          </div>
          )}
        </section>
      </main>
       {/* For Showing Hub Info */}
       <ModalHubInfo
            isShow={modalToggle.hub_info}
            toggleModal={(e, state) => toggleModal(e, 'hub_info', state)}
            loader={hubInfoLoading}
            data={hubModalDetails}
        />
        </>
    )    
}

export default HubList;