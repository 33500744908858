import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {SetMetaData} from './../../helper/page-title';
import NotFound from "../404";
import {LoaderMedium} from './../../components/loader';
import {post_api_call, error_api_call} from '../../actions/action-creators/common';
import {alertResponseMessage} from '../../actions/action-creators/response';
// import {linkHttp} from '../../helper/custom-function';
import {HubDetailComp} from '../../components/hub/detail';

function HubDetail(){
    const { hub_url } = useParams();
    SetMetaData("Opportunity Details", "", "");
    const [pageLoading, setPageLoader] = useState(true);
    //Main Data
    const [mMasterData, setPortfolioData] = useState({});
    SetMetaData(typeof mMasterData.title !== "undefined" ? mMasterData.title : "", "", "");
    const [showNotFoundPage, setShowNotFoundPage] = useState(false);

	//Get Portfolio Details
    const fetchHubData = async() => {
        try{
            const fetchApiData = await post_api_call(`hub/details/${hub_url}`, {});
            if(fetchApiData.status === 200){                    
                setPortfolioData(fetchApiData.data);
            }else if(fetchApiData.status === 404){
                setShowNotFoundPage(true);
            }
            setPageLoader(false);
        }catch(e){
            console.log(e);
            alertResponseMessage({alert_type: 2, message: "Error Loading Details! Check back after some times"});
            error_api_call({error: `hub/detail|fetchHubData|${e}`});
        }
    }
	//Intial Fetching
	useEffect(() => {
        fetchHubData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <main id="main">
            <section className="team-sec pt-0 portfolio-edit">
                {/* <div className="container-fluid container-lg pe-lg-5 ps-lg-5"> */}
                <div className="container-fluid container-lg">
                    <div className="section-header section-header-1">
                        <h1 className="title">Opportunity Details</h1>
                    </div>

                    <div className="row justify-content-center team-detail m-1">
                        {!pageLoading && (
                            <div className="col-md-9 col-12 col-sm-12 p-3 pe-0">
                                <div className="text-end mb-0">
                                    <a href="/hub" rel="noreferrer"><button className="btn btn-primary bg-color-blue me-sm-3 mb-1"> View All Opportunities</button></a>
                                </div>
                            </div>
                        )}
                        <div className="col-md-9 col-12 col-sm-12">
                            {/* Show Loader */}
                            {pageLoading 
                                ?
                                <div className="text-center mt-5 mb-5">
                                        <LoaderMedium size={45} />
                                </div>
                                :
                                <>
                                    {mMasterData && typeof mMasterData.title !== "undefined" && mMasterData.title !== '' && (
                                        <>
                                        <div id="portfolio" className="hub-modal mt-3 mb-3">
                                            <HubDetailComp
                                                data={mMasterData}
                                                fetch_type={'page'}
                                            />
                                        {/* <div className="p-2" dangerouslySetInnerHTML={ { __html: portfolioData[hub_url].detail}}> */}
                                            {/* {portfolioData[hub_url].detail} */}
                                        {/* </div> */}
                                        </div>
                                        </>
                                    )}
                                    {showNotFoundPage && (
                                        <NotFound type="hub" />
                                    )}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )    
}

export default HubDetail;